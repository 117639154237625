const pbStyle = {
    margin: 0,
    top: 'auto',
    left: 0,
    bottom: 0,
    right: 'auto',
    position: 'fixed',
    paddingBottom: 20
};

export {
    pbStyle
};