import React, { useState, useEffect, useMemo } from "react";
import { styled } from '@mui/material/styles';
import _ from "lodash";

import { navigate } from "gatsby";

import { Grid, Box, Typography } from "@mui/material";

import { PlayArrow as PlayArrowIcon, Clear as ClearIcon } from '@mui/icons-material';

import { getEntityUrl } from "../util";


const StyledBox = styled(Box)(() => ({

    //padding: theme.spacing(3, 2),
    // height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontWeight:"700",
    // "&:hover": { backgroundColor: "red"},
    "&:active": { backgroundColor: "#999" },
}));

const defaultProps = {
    //bgcolor: 'background.paper',
    borderColor: "#ddd",
    m: 1,
    border: '1px solid #979797',
    style: { width: "5rem", height: "5rem" },
    fontWeight: '700'
};

const EntityKeypad = ({ entity, pageContext }) => {
    const { defaultLocale } = pageContext;

    const entities = useMemo(() => {
        const entities = {};
        entity.strapiChildren.forEach(({ Roles, identifier, title: caption, locale }) => {
            const listRole = _.find(Roles, { strapi_component: 'role.list-child' });
            const numCode = parseInt(listRole?.numCode || 0);
            if (numCode) entities[numCode] = { identifier, caption, locale };
        });
        return entities;
    }, [entity]);

    let buttons = [...Array(10).keys()];
    buttons.push('clr')
    buttons.push(buttons.shift());
    buttons.push('play');

    const [keyEntered, setKeyEntered] = useState(' ');
    const [playEnabled, setPlayEnabled] = useState(false);

    const buttonClicked = (key) => {

        switch (key) {
            case 'clr':
                setKeyEntered('');
                setPlayEnabled(false);
                break;
            case 'play':
                navigate(getEntityUrl({ entity: entities[parseInt(keyEntered)], defaultLocale }));
                break;
            default:
                setKeyEntered(keyEntered + key);
        }
    };

    useEffect(() => {
        const checkEntry = () => {
            let entry = parseInt(keyEntered);
            if (entities[entry])
                setPlayEnabled(true);
            else
                setPlayEnabled(false);
        };

        let timer;
        if (keyEntered.length >= 1) {//TODO - hardcoded 3
            timer = setTimeout(checkEntry, 200);
        }
        return () => {
            if (timer) { clearTimeout(timer); timer = null; }
        };
    }, [keyEntered, entities]);//TODO - move entities-creation to useMemo, react sez


    return (
        <div align="center" className="keypadContainer">
            <Grid container style={{ maxWidth: 450, }} spacing={1} justifyContent="center" height="90vh">
                <Grid item xs={12} align="center">
                    <Box {...defaultProps} m={0} style={{ height: '7rem' }} bgcolor="transparent" display="flex" alignItems="center" border="none" justifyContent="center">
                        <Grid container><Grid item xs={12}><Typography variant="h4" fontSize="3rem" >{keyEntered}</Typography></Grid>
                            <Grid item xs={12} style={{ minHeight: '50px', padding: '0 10px' }}><Typography variant="caption" lineHeight="1" fontSize="1.1rem"  >{playEnabled && entities[parseInt(keyEntered)]?.caption}</Typography></Grid></Grid>
                    </Box>
                </Grid>

                {buttons.map((item) => {
                    //item = ++item;
                    return (
                        <Grid key={item} item xs={4} align="center">
                            <StyledBox onClick={() => buttonClicked(item)} borderRadius="50%" lineHeight="0"  {...defaultProps} >
                                <KeypadButton {...{ playEnabled, item }} />
                            </StyledBox>
                        </Grid>


                    );
                })}
            </Grid>
        </div>
    );
};

const KeypadButton = ({ item, playEnabled }) => {
    //const theme = useTheme();
    switch (item) {
        case 'clr':
            return <h4><ClearIcon color="error" sx={theme => ({ fontSize: theme.typography.h4.fontSize })} /></h4>;
        case 'play':
            return <h4><PlayArrowIcon disabled={!playEnabled} sx={theme => ({ color: playEnabled ? 'green' : 'lightgray', fontSize: theme.typography.h4.fontSize /*TODO - should be action color forom theme */ })} /></h4>;
        default:
            return <Typography component="h4" variant="h4">{item}</Typography>;//TODO - make button?
    }
};

export default EntityKeypad;
