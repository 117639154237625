import React, { useState, useEffect } from "react";

import Fab from '@mui/material/Fab';
import CloudDownload from '@mui/icons-material/CloudDownload';

const fabStyle = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
};

const DlFab = ({ onClick, installState, children }) => {

    const [fabHidden, setFabHidden] = useState(true);
    useEffect(() => {
        if (installState === 'installable')
            setFabHidden(false);
        else
            setFabHidden(true);
    }, [setFabHidden, installState]);

    return <>
    { fabHidden === false &&
        <Fab style={fabStyle} aria-label={'Download'} color={'primary'} onClick={onClick}>
            <CloudDownload />
            {children}
        </Fab>
    }
    </>
}

export default DlFab;