import React, { useState } from 'react';

import Wrapper from "../../components/Wrapper";

import { IndexView as ListView } from './views';

import EntityKeypad from "../../components/Keypad";

const Default = (props) => {
    const { entity, config, selectedModule } = props;

    const [keypadShown, setKeypadShown] = useState(false);//TODO - survive reload, check cookbook ch3 - redux-reducer, gatsby redux example, gatsby-redux-plugin
    const keypadToggle = () => {
        setKeypadShown(!keypadShown);
    };

    return selectedModule !== 'webflow' ? 
        <></>
        :
        <Wrapper {...props} title={entity.title} keypadSwitch={config.showKeypad || false} keypadShown={keypadShown} onKeypad={keypadToggle}>
        {keypadShown ? 
            <EntityKeypad {...{entity}} {...props} /> :
            <ListView.Controller entity={entity} {...props} />
        }    
        </Wrapper>
    
};

export default Default;
