import React from "react";
import { styled } from '@mui/material/styles';
import _, { before } from "lodash";

import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"

import { Link } from "gatsby"

import { getEntityUrl, getOverlayImages } from "../../util";
import Wrapper from "../../components/Wrapper";


import "core-js/proposals/string-match-all";//polyfill for old Androids; TODO - move away from here


const Root = styled('div')(({ theme }) => ({
    height: "100vh",
    textTransform:"uppercase"
}));
Root.defaultProps = {
    "data-id": "Root"
  };

const BackgroundImage = styled(BgImage)(({ theme }) => ({
    flexGrow: 0,
    backgroundSize: "cover",
    width:"100%",
    height: 'calc(100vh - 59px)',

}));
BackgroundImage.defaultProps = {
    "data-id": "BackgroundImage"
  };

const Logo = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 10,
    left: 16,
    background: 'url(/images/logo-kinemathek.png) no-repeat',
    height: 120,
    color: 'transparent',
}));
Logo.defaultProps = {
    "data-id": "Logo"
  };

const Claim = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 181,
    left: 16,
    height: 60,
    background: 'url(/images/audioguide.png) no-repeat',
    color: 'transparent',
    // '&:before': {
    //     content: '""',
    //     position: 'absolute',
    //     bottom: 0,
    //     left: 50,
    //     width: '100%',
    //     height: '1rem',
    //     display: 'block',
    //     background:'hotpink'
    // }
}));

const Children = styled('div')(({ theme }) => ({
    position:"absolute",
    bottom: "10px",
    left: "10px"
}));

const ChildLink = styled(Link)(({ theme }) => ({
    background: 'var(--home-childlink-bg)',
    padding: '7px 15px',
    marginTop: 10,
    display: 'block',
    borderRadius: 4,
    width: 'fit-content',
    textDecoration: 'none',
    color: '#fff',
    fontSize: '1.3rem',
}));

const ChildDiv = styled('div')(({ theme }) => ({
}));

const EntityPage = (props) => {

    const { entity, pageContext, selectedModule, files } = props;
    const { strapiChildren: children } = entity;

    const image = getImage(entity.largeImage?.localFile);
    const { defaultLocale } = pageContext;

    return <Root>
        <Wrapper className="wrapper" {...props} topbar={false} title={entity.title} transparentAppbar={true}>
            <div className="wrapper2" style={{ overflowX: 'hidden' }/*TODO - var*/}>
                <BackgroundImage className="background"  image={image}>
                    <Logo>{entity.title}</Logo>
                    <Claim className="claim______">{entity.shortDesc}</Claim>
                    <Children className="linkliste" >
                        {children.map((child, n) => {
                            const url = getEntityUrl({ entity: child, defaultLocale });

                            return <ChildLink className="childlink___" to={url}>{child.title}</ChildLink>
                        })}
                    </Children>
                </BackgroundImage>

            </div>
        </Wrapper>
    </Root>
}

export default EntityPage;