import React, { useMemo, useState, useEffect, useRef, useCallback, useContext } from "react";

import { styled } from '@mui/material/styles';

import _ from "lodash";

import Player from '../Player';

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { Grid, Box, Typography } from "@mui/material";

import Collapse from '@mui/material/Collapse';

import PlayArrow from "@mui/icons-material/PlayArrow";
import Pause from "@mui/icons-material/Pause";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

import { GlobalContext } from '../Wrapper';

const TopArea = styled(Grid)(({ theme }) => ({
    marginLeft: '2px',
    marginRight: '2px',
}));

const Left = styled(Grid)(({ theme }) => ({
     /* Reni-Style */
     flexGrow: '0',
     flexBasis: '0%',
     maxWidth: '1080px',

}));

const Middle = styled(Grid)(({ theme }) => ({
    flexGrow: '4',
    flexBasis: '0%',
    maxWidth: '1080px',
    paddingLeft: "0px",
}));

const Right = styled(Grid)(({ theme }) => ({
    paddingRight: '24px',
    textAlign: 'right',

     /* Reni-Style */
    color: '#222222',
    flexGrow: '0',
    flexBasis: '0%',
    maxWidth: '1080px',
}));

const Text1 = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",

     /* Reni-Style */
    fontFamily: "opensans",
    fontSize: "15px",
    color:  theme.palette.mode === 'light' ? '#222' : '#fff',
    lineHeight: "1",
    paddingBottom: '4px',
}));

const Text2 = styled(Typography)(({ theme }) => ({

    /* Reni-Style */
    color:  theme.palette.mode === 'light' ? '#222' : '#fff',
    fontFamily: "opensans",
    fontSize: "14px",
    lineHeight: "1.2",
}));

const Text3 = styled(Typography)(({ theme }) => ({
    fontWeight: 300,

    /* Reni-Style */
    color: theme.palette.mode === 'light' ? '#222' : '#fff',
    fontFamily: "opensans",
    fontSize: "13px",
    lineHeight: "1.2",
}));

const LeftImage = styled(GatsbyImage)(({ theme }) => ({

    /* Reni-Style */
    marginTop: "-8px",
    marginBottom: "0px",
    height: "90px",
    width: "100px",
    marginLeft: '-10px'
}));

const BorderBox = styled(Typography)(({ theme }) => ({
    /* Basic-Style
    border: "1px solid",
    */

    /* Reni-Style */
    background: theme.palette.mode === 'light' ? '#efefef' : '#1b1b1b',
    border: "0px solid #fff",
    // marginBottom: "8px",


}));

const Imageslider = (props) => {
    const { component, files, pageContext } = props;

    const image = useMemo(() => {
        return _.find(files.nodes, {id: component.thumbnail?.localFile___NODE});
    }, [files, component]);

    const audioUrl = useMemo(() => {
        return _.find(files.nodes, { id: component.audio?.localFile___NODE })?.publicURL;
    }, [files, component]);

    const line1 = component.line1 || '';
    const line2 = component.line2 || '';
    const line3 = component.line3 || '';

    const [playerStyle, setPlayerStyle] = useState('');

    const { defaultLocale } = pageContext;


    const [playing, setPlaying] = useState(false);
    const [playerVisible, setPlayerVisible] = useState(false);

    const playerRef = React.createRef();

    const { setPlayer } = useContext(GlobalContext);

    const PlayerIcon = useMemo(() => playing ? Pause : PlayCircleFilledIcon, [playing]);

    const onPlay = useCallback(() => {
        setPlaying(true);
    }, [setPlaying]);

    const onPause = useCallback(() => {
        setPlaying(false);
    }, [setPlaying]);

    const onEnded = () => {
        //TODO - what to do?
    }

    const toggleTimeout = useRef();
    const togglePlayer = useCallback(() => {
        //if (!playerVisible && !playing) playerRef.current.getInternalPlayer().play();
        if (toggleTimeout.current) clearTimeout(toggleTimeout.current);

        if (playing) {
            playerRef.current?.getInternalPlayer().pause();
            toggleTimeout.current = setTimeout(() => setPlayerVisible(!playerVisible), 10000);
        } else {
            setPlayerVisible(!playerVisible);
        }
    }, [playerRef, toggleTimeout]);

    const onPlayerVisible = useCallback((node, done) => {
        node.addEventListener('transitionend', () => {
            if (component.autoplayAudio && !playing && playerVisible) {
                const internalPlayer = playerRef.current?.getInternalPlayer();
                if (internalPlayer) {
                    setPlayer(internalPlayer);
                    internalPlayer.play();
                }
            }
            done();
        });
    }, [playerRef, setPlayer]);

    return (<Grid container spacing={0}>
        <Grid item xs={12}>
            <BorderBox>
                <TopArea container spacing={1} justifyContent="center"
                    alignItems="center" onClick={togglePlayer}>

                    <Left item xs={4}>
                        <LeftImage image={getImage(image)} alt="" />
                    </Left>

                    <Middle item xs={7}>
                        <Text1>{line1}</Text1>
                        <Text2>{line2}</Text2>
                        <Text3>{line3}</Text3>
                    </Middle>

                    <Right item xs={1}>
                        <PlayerIcon fontSize="large" color="secondary" />
                    </Right>

                </TopArea>
            </BorderBox>
        </Grid>
        {
            audioUrl !== undefined &&
            <Grid item xs={12}>
                <Collapse in={playerVisible} addEndListener={onPlayerVisible} timeout={350}>
                    <BorderBox sx={{ marginTop: "-1px" }}>
                        <Player ref={playerRef}
                            url={audioUrl}
                            controls={true}
                            loop={false /* TODO - make configurable */}
                            playing={component.autoplayAudio || false}
                            playsinline={component.fullscreen || false}
                            showTimeControls={component.showTimeControls === false ? false : true}
                            onEnded={onEnded}
                            onPlay={onPlay}
                            onPause={onPause}
                        />
                    </BorderBox>
                </Collapse>
            </Grid>
        }

    </Grid>
    );
}

export default Imageslider;