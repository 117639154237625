import React, { useEffect, useState, useMemo, useRef, useCallback } from "react"
import _ from "lodash";

import ProgressBar from "../ProgressBar";

import DlFab from "../DlFab";

import { useStaticQuery, graphql } from "gatsby"

import { getUrls, getOfflineComponent, preloadEntityPages, traverseChildren } from "./util";
import { useLocalStorage } from "../hooks";

import { pbStyle as progressStyle } from "./style";

import { getTranslatedTopbarEntities } from "../../util";

const Pwa = ({ pageContext, entity }) => {
    const { locale, entityId, defaultLocale, files } = pageContext;

    const { allStrapiEntities: entities, allStrapiAppSettings: settings } = useStaticQuery(staticQuery);
    const offlineComponent = getOfflineComponent(entity);
    const topbarEntities = getTranslatedTopbarEntities(settings);

    const [installState, setInstallState] = useLocalStorage(`pwa.installState_${entityId}_${locale}`, offlineComponent?.hasDownload && 'enabled');
    const [installing, setInstalling] = useLocalStorage('pwa.installing', false);
    const [curProgress, setCurProgress] = useState(0);

    useEffect(() => {//something went wrong, best effort to recover
        if (installState === 'installing') {
            setInstallState('enabled');
            setInstalling(false);
        }
    }, []);

    useEffect(() => {
        if (installState === 'enabled') {
            async function awaitSW() {
                await navigator.serviceWorker.ready;//TODO - errors in dev when on non-localhost-non-ssl connection
                setInstallState('installable');
            }

            awaitSW();
        } 
    }, [setInstallState, installState]);

    useEffect(() => {
        if (curProgress === 100 && installState === 'installing' && installing?.entityId) {
        
            if (installing?.entityId !== entityId || installing.locale !== locale) {
                if (typeof window !== `undefined`) {
                    window.localStorage.setItem(`pwa.installState_${installing?.entityId}_${installing.locale}`, 'done');
                }
            } else {
                setInstallState('done');
            }

            setInstalling(false);
        }
    }, [curProgress, setInstallState, installState, installing, setInstalling, locale, entityId]);

    const downloadableEntities = useMemo(() => {
        const rv = [];
        if (offlineComponent) {
            rv.push(entity);
            if (offlineComponent.includeChildren) {
                rv.push(...traverseChildren(entity?.strapiChildren, entities.nodes));
            }

            //add menu-items if they have download on
            if (topbarEntities) rv.push(...traverseChildren(topbarEntities, entities.nodes));
        }
        
        return rv;
    }, [entity, entities, offlineComponent, topbarEntities]);

    const downloadAll = () => {
        const { mediaUrls, pageUrls } = getUrls(downloadableEntities, files, defaultLocale);
        
        setInstallState('installing');
        setInstalling({ entityId, locale });
        setCurProgress(1);
        
        preloadEntityPages({ pageUrls });
        preloadMedia({ mediaUrls });
    };

    const preloadMedia = useCallback(async ({ mediaUrls = [] }) => {
        let installedCount = 0;
        let cache = null;

        if (typeof window !== `undefined`) {
            await navigator.serviceWorker.ready;

            mediaUrls.forEach(async (url, i) => {//TODO - get file sizes through HEAD request first
                if (process.env.NODE_ENV !== `production`) console.log(`downloading ${url}`);
                if (cache === null)
                    cache = await caches.open('erwin-offline-static');

                await cache.add(url);

                installedCount++;
                setCurProgress(parseInt(installedCount/mediaUrls.length*100));
            });
        }
    }, [setCurProgress]);



    return <>
        <ProgressBar style={progressStyle} progress={curProgress} {...{installing}} />

        <DlFab installState={installState} onClick={downloadAll} />{/*{curProgress}</DlFab>*/}
    </>
}

export default Pwa;

const staticQuery = graphql`query  {
                allStrapiEntities {
                    nodes {
                        identifier
                        smallImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 150)
                                }
                            }
                        }
                        largeImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                        }
                        Components
                        strapiChildren {
                            identifier
                            locale
                        }
                        locale
                    }
                }
                allStrapiAppSettings {
                    nodes {
                        Topbar {
                            languageSwitch
                            entities {
                                identifier
                                title
                                locale
                            }
                        }
                    }
                }       
            }`;


    /* listen to serviceWorker cleanly - unneeded for now
    const onSWMsg = useCallback((event) => {

    }, [setCurProgress, setInstallState, installState, installing, setInstalling, locale, entityId]);

    const onSWMsgRef = useRef();
    useEffect(() => {
        onSWMsgRef.current = onSWMsg;
    }, [onSWMsg]);

    useEffect(() => {
        if (typeof navigator !== `undefined` && 'serviceWorker' in navigator) {
            const throttledOnSWMsg = _.throttle((event) => onSWMsgRef.current(event), 100);

            navigator.serviceWorker.addEventListener('message', throttledOnSWMsg);

            return () => {
                if (typeof navigator !== `undefined` && 'serviceWorker' in navigator) 
                    navigator.serviceWorker.removeEventListener('message', throttledOnSWMsg);
            };
        }
    }, []);
    /***********************************/
