import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";

import { useLocalStorage } from "./hooks";

import DlFab from "./DlFab";

const IDANativeBridge = ({ children, ...props }) => {
    const { entity } = props;
    const mediaControlRole = _.find(entity.Roles, { strapi_component: 'role.media-control' });

    const [isInstalled, setIsInstalled] = useLocalStorage(`idanative.content.installState.partial.${entity.locale}.${entity.identifier}`, false);

    const [dlFabState, setDlFabState] = useState('hidden');
    useEffect(() => {
        if (typeof window === undefined || !window.isIDANativeApp) {
            return;
        }

        if (mediaControlRole?.excludeFromNativeContent && mediaControlRole.excludeFromNativeContent === 'download') {
            if (!isInstalled) {
                setDlFabState('installable')
            }
        }

    }, [setDlFabState, isInstalled]);

    useEffect(() =>{
        if (typeof window !== 'undefined') {
            document.addEventListener('onIDANative', ({ detail: payload }) => {
                switch (payload.action) {
                    case 'partialContentInstalled':
                        const { identifier, locale } = payload;
                        if (identifier === entity.identifier && locale === entity.locale) {
                            setIsInstalled(true);
                        }
                        break;
                    case 'contentUpdated':
                        purgeIsPartialInstalledStates();
                        break;
                    default:
                        break;
                }
            });
        }
    }, [setIsInstalled, entity]);

    const purgeIsPartialInstalledStates = useCallback(() => {
        if (typeof window === undefined || !window.isIDANativeApp) {
            return;
        }
        
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith('idanative.content.installState.partial.')) {
                localStorage.removeItem(key);
            }
        })
    });

    const onDLFabClick = useCallback(() => {
        const nativeData = { action: 'installPartialContent', entity };
        window.ReactNativeWebView.postMessage(JSON.stringify(nativeData));
    });

    return <>
        {children}
        <DlFab installState={dlFabState} onClick={onDLFabClick} />{/*{curProgress}</DlFab>*/}

    </>

};

export default IDANativeBridge;