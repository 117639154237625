import React, { useEffect, useRef } from "react";

const CrowdsignalPoll = ({ component }) => {
    const pollRef = useRef(null);

    useEffect(() => {
        (async () => {
            if (typeof window !== "undefined" && pollRef) {

                if (component?.pollURL) {
                    const postscribe = await import('postscribe');

                    const pollURL = new URL(component.pollURL.trim());
                    const pollScript = `<script type="text/javascript" src="https://secure.polldaddy.com/p${pollURL.pathname}.js"></script>`;

                    postscribe.default(
                        pollRef.current,
                        pollScript,
                        { error: (e) => console.log(e) }
                    );

                }

            }
        })();
    }, [pollRef]);

    return <div style={{ position: 'absolute', right: '50%', transform: 'translate(50%,0)' }} ref={pollRef} />
}

export default CrowdsignalPoll;