import React from 'react';
import _ from 'lodash';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import FullScreenWrapper from './FullscreenWrapper';


const Comparisonslider = (props) => {
    const { entity, component, files, fullscreenMode, pageContext } = props;
    const { defaultLocale } = pageContext;
    const leftImage = _.find(files.nodes, { id: component.leftImage.localFile___NODE });
    const rightImage = _.find(files.nodes, { id: component.rightImage.localFile___NODE });

    return <FullScreenWrapper {...props}>
        <ImgComparisonSlider style={{width:'100%', maxHeight:'100vh'}}>
            <img slot="first" width={'100%'} src={leftImage.publicURL} />
            <img slot="second" width={'100%'} src={rightImage.publicURL} />
        </ImgComparisonSlider>
    </FullScreenWrapper>
}

export default Comparisonslider;