import React from 'react'
import { styled } from '@mui/material/styles';
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import IndexView from '../views/IndexView'

const PREFIX = 'IndexController';

const classes = {
    img: `${PREFIX}-img`
};

const StyledIndexView = styled(IndexView)((
    {
        theme
    }
) => ({
    [`& .${classes.img}`]: {
        display: 'block',
    }
}));

const IndexController = ({ entity, ...props }) => {

    const children = entity.strapiChildren;
    const image = entity.largeImage ? getImage(entity.largeImage.localFile) : null;
    const headline = (entity.shortDesc||' ').replace(/(?:\r\n|\r|\n)/g, '<br />');

    return (
        <StyledIndexView>
            <headline>{headline}</headline>

            {image !== null && <header-image>
                <GatsbyImage className={classes.img} image={image} alt={headline} />
            </header-image>
            }
            
            {children.map(child => {return <list-item-view key={child.identifier} entity={child} {...props} />})}
        </StyledIndexView>
    );
}

export default IndexController;