import React, { useMemo, useState, useEffect, useRef, useCallback } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { navigate } from "gatsby-link";

import { styled } from '@mui/material/styles';

import _ from "lodash";

import Player from '../Player';
import FullscreenWrapper from '../FullscreenWrapper';

import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { Box, } from "@mui/material";

import Carousel from '../Carousel';

import { getEntityUrl } from '../../util';

// import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './imageslider-advanced.css';


const Root = styled('div',
    { shouldForwardProp: () => true }
)(({ height }) => ({
    // marginBottom: '6px',
    // height: `${height}vh`,
    // backgroundColor: '#efefef'
}));


const OverlayText = styled('div')(() => ({
    position: 'absolute',
    zIndex: 65535,
    // maxHeight: '100px',
    // overflowY: 'scroll',
    display: 'block',
}));

const OverlayToggle = styled('div')(() => ({//TODO
    display: 'block',
    position: 'absolute',
    bottom: 6,
    left: 5,
    zIndex: 99999,
    color: '#fff',
    lineHeight: 1,
    padding: '5px 5px 2px 5px',
    background: 'rgb(0 0 0 / 34%)',
    borderRadius: '100%',
    margin: 0,

}));
const CaptionsDiv = styled('div')(() => ({
    // maxHeight: '250px',
    // overflow: 'hidden',
    flex: 1,
}));

const FlexDiv = styled('div')(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
}));


const ImagesliderAdvanced = (props) => {
    const { component: { height, allowFullscreen, ...component }, files, fullscreenMode, pageContext } = props;

    //const { height }

    const images = useMemo(() => {
        const images = [];
        _.each(component.image,
            ({ image, caption, overlayText }) => {

                images.push({
                    localFile: _.find(files.nodes, { id: image?.localFile___NODE }),
                    caption,
                    overlayText,
                    id: image.id
                })
            });


        return images;
    }, [files, component]);

    const zoomable = useMemo(() => fullscreenMode ? true : false, [fullscreenMode]);

    const [caption, setCaption] = useState('');
    const [overlayText, setOverlayText] = useState('');

    const onCarouselChange = useCallback((i) => {
        setCaption(images[i].caption);
        setOverlayText(images[i].overlayText);
    }, [images, setCaption, setOverlayText]);

    const carouselProps = {
        infiniteLoop: true,
        fullHeightHover: false,
        interval: 0,
        autoPlay: false,
        showThumbs: false,
        showStatus: false,
        swipeScrollTolerance: 30,
        swipeable: !zoomable,
        onChange: onCarouselChange,
    };

    const [zoomRef, setZoomRef] = useState(null);
    const zoomRefCb = useCallback((node) => {
        setZoomRef(node);
    }, [setZoomRef]);

    useEffect(() => {
        const centerZoom = () => {
            setTimeout(() => { if (zoomRef) zoomRef.centerView() }, 50);
        }

        window.addEventListener('resize', centerZoom);
        return () => window.removeEventListener('resize', centerZoom)
    }, [zoomRef, onCarouselChange]);

    useEffect(() => {
        onCarouselChange(0);
    }, []);//init

    const onImageLoad = useCallback(() => { if (zoomRef) zoomRef.centerView(undefined, 1) }, [zoomRef]);

    const zoomWrapperStyle = { width: '100%', margin: '0 auto' };
    if (fullscreenMode) {
        zoomWrapperStyle.maxHeight = '-webkit-fill-available';
        zoomWrapperStyle.height = '100vh';
    }



    return (<Root height={height} className="imageslider-advanced" >
        <FullscreenWrapper {...props} showFullscreenButton={allowFullscreen} >
            {
                images.length > 0 && (
                    <FlexDiv>
                        <Carousel carouselProps={carouselProps} className="imageslider" fullscreenMode={fullscreenMode} hasCaption={true}>
                            {images.map((i, n) =>
                                <>
                                    <OverlayToggle className="overlay-toggle" ><InfoOutlinedIcon fontSize="medium" /></OverlayToggle>

                                    <OverlayText className="richtext overlay-text" dangerouslySetInnerHTML={{ __html: overlayText }} />

                                    <Box key={i.id} align="center">
                                        <TransformWrapper centerOnInit={true} disabled={zoomable !== true} ref={zoomRefCb} >
                                            <TransformComponent wrapperStyle={zoomWrapperStyle} wrapperClass='zoomWrapper'>
                                                <GatsbyImage
                                                    onLoad={onImageLoad}
                                                    image={getImage(i.localFile)}
                                                    alt=""
                                                    objectFit="contain"
                                                    loading="eager"
                                                    imgStyle={{ position: 'static', height: `${height}vh`, verticalAlign: 'center' }} />
                                            </TransformComponent>
                                        </TransformWrapper>

                                    </Box >
                                </>
                            )}
                        </Carousel >
                        {caption !== '' &&
                            <CaptionsDiv className="richtext caption-inline">
                                <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: caption }} />
                            </CaptionsDiv>}
                    </FlexDiv>
                )
            }
        </FullscreenWrapper >
    </Root >
    );
}

export default ImagesliderAdvanced;
