import * as React from "react";

import { styled } from '@mui/material/styles';

import _ from "lodash";

import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    CardMedia,
} from "@mui/material";

import { getImage, GatsbyImage } from "gatsby-plugin-image";

const PREFIX = 'Header';

const classes = {
    card: `${PREFIX}-card`,
    cardTop: `${PREFIX}-cardTop`,
    media: `${PREFIX}-media`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    /* TODO - dup - move to layout/theme */
    [`& .${classes.card}`]: { width: "100%", margin: theme.spacing(1, 0) },

    [`& .${classes.cardTop}`]: { width: "100%", height: 300 },

    [`& .${classes.media}`]: {
        width: "100%",
        height: "100%",
    }
}));

const Header = ({ entity }) => {


    const headerTitle = (entity.shortDesc || '').replace(/(?:\r\n|\r|\n)/g, '<br />') || false;
    const oaiRole = _.find(entity.Roles, { strapi_component: 'role.oai-config', syncIsExhibited: true });

    return (
        <Root>
            {entity.largeImage !== null &&
                <Card className={classes.cardTop}>
                    {/* <CardHeader title="" subheader="" />*/}
                    <CardMedia xs={12} className={classes.media} >
                        <GatsbyImage image={getImage(entity.largeImage.localFile)} alt="" />
                    </CardMedia>
                    <CardContent>
                        <Typography></Typography>
                    </CardContent>
                </Card>
            }
            {headerTitle !== false &&
                <Card className={classes.card}>
                    <CardHeader title={<span dangerouslySetInnerHTML={{ __html: headerTitle }} />} align="center" />
                    {oaiRole?.isExhibited === false &&
                        <CardContent>
                            Not Exhibited
                        </CardContent>
                    }
                </Card>}
        </Root>
    );
}

export default Header;