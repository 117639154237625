/* eslint-disable */

import React from 'react'
import { Link } from "gatsby"

import { createScope, map, transformProxies } from './helpers'


const scripts = [

]

let Controller

class ListItemView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ListItemController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ListItemView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    /* View has no WebFlow data attributes */

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = ListItemView.Controller !== ListItemView ? transformProxies(this.props.children) : {
      'list-item-link': [],
      'list-item-image': [],
      'list-item-title1': [],
      'list-item-title2': [],
      'list-item-title3': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/erwin-list.webflow.css);
        ` }} />
        <span className="af-view">
          <li id="list_item" className="af-class-audio-list-item">
            {map(proxies['list-item-link'], props => <Link {...{...props, className: `af-class-audio-link w-inline-block ${props.className || ''}`}}>{createScope(props.children, proxies => <React.Fragment>
              {map(proxies['list-item-image'], props => <div {...{...props, className: `af-class-thumb ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="/images/wolgemut.jpg" loading="lazy" alt="" className="af-class-thumb-image" /></React.Fragment>}</div>)}
              <div className="af-class-titelflex">
                <div className="af-class-titelcontainer">
                  {map(proxies['list-item-title1'], props => <div {...{...props, className: `af-class-titel1 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Johann Wolfgang Emanuel Tischbein der ältere</React.Fragment>}</div>)}
                  {map(proxies['list-item-title2'], props => <div {...{...props, className: `af-class-titel2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Ein Tag am Meer und die Freuden der Sonne</React.Fragment>}</div>)}
                  {map(proxies['list-item-title3'], props => <div {...{...props, className: `af-class-titel3 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Um 1650</React.Fragment>}</div>)}
                </div>
              </div>
              <div className="af-class-icon"><img src="/images/arrow-s.svg" loading="lazy" alt="" /></div>
            </React.Fragment>)}</Link>)}
          </li>
        </span>
      </span>
    )
  }
}

export default ListItemView

/* eslint-enable */