import React, { useEffect, useMemo, useState } from 'react';
import _ from "lodash";

import { MapContainer, Popup, Marker, ImageOverlay, CircleMarker } from "react-leaflet";
import { CRS } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { Link } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { styled } from '@mui/material/styles';

import { getEntityUrl } from "../../util";
import FullscreenWrapper from '../FullscreenWrapper';

import './style.css';

const DEFAULT_ZOOM = 16; //TODO - hardcoded init'l zoom of 15 for projection; sane ?

const MapDiv = styled('div')(({
    position: 'relative',
    width: '100%',


}));

const PopupDiv = styled('div')(({
}));

const PopupImageDiv = styled('div')(({

}));

const PopupTextDiv = styled('div')(({

}));

const IndoorMap = (props) => {
    const { component, entity, pageContext, fullscreenMode } = props;
    const { defaultLocale, files } = pageContext;

    const image = useMemo(() => {
        return _.find(files.nodes, { id: component.mapImage.localFile___NODE });
    }, [component]);


    const [Container, setContainer] = useState(React.Fragment);
    const [map, setMap] = useState(null)

    useEffect(() => {
        setContainer(window === undefined ? React.Fragment : MapContainer);
    }, [setContainer]);

    useEffect(() => {//required to get icons offline and have ssr working
        const L = require("leaflet");

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
            iconUrl: require("leaflet/dist/images/marker-icon.png").default,

            //custom-marker statisch in der app:
            //iconRetinaUrl: require("../images/erwin-icon.png").default,
            //iconUrl: require("../images/erwin-icon.png").default,

            //brauchen wir custom-shadows?
            shadowUrl: require("leaflet/dist/images/marker-shadow.png").default
        });

    }, []);

    const [imageDimensions, setImageDimensions] = useState({});
    const [bounds, setBounds] = useState();
    useEffect(() => {
        const img = new Image();
        img.src = image.publicURL;

        img.onload = () => {
            setImageDimensions({
                height: img.height,
                width: img.width
            });
        };
    }, [setImageDimensions]);

    useEffect(() => {
        const L = require("leaflet");

        if (map && imageDimensions.height) {
            const L = require('leaflet');
            const southWest = map.unproject([0, imageDimensions.height], DEFAULT_ZOOM);
            const northEast = map.unproject([imageDimensions.width, 0], DEFAULT_ZOOM);

            entity.strapiChildren.forEach(c => {
                const { Roles } = c;
                const role = _.find(Roles, { strapi_component: 'role.indoor-map-child' });
                if (role) {
                    role.position.coordinates = map.unproject([role.position.x, role.position.y], DEFAULT_ZOOM);
                }

                // als beispiel das small image des childs als marker um "custom" marker aus cms
                // - in diesem fall per child - zu demonstrieren
                // unabhängig ob marker global, per map oder per entity definiert,
                // brauchen wir im cms neben grösse dann auch die "anchor" points des jeweiligen markers -
                // also wo die "spitze" ist und wo die spitze des popups auf dem marker enden soll

                //********************
                //Variante 2 wäre ein Div Icon    https://leafletjs.com/reference.html#divicon
                //********************

                if (c.smallImage?.localFile !== undefined) {
                    const img = getImage(c.smallImage.localFile);
                    role.mapIcon = L.icon({
                        iconUrl: img.images.fallback.src,
                        //TODO - sizes, anchor and all...
                        iconSize: [55, 55],
                        iconAnchor: [27, 55],
                        popupAnchor: [0, 14],

                        //shadowUrl: 'my-icon-shadow.png',
                        //shadowSize: [68, 95],
                        //shadowAnchor: [22, 94]

                    });
                }


            });

            const localBounds = new L.LatLngBounds(southWest, northEast);
            //const imgLayer = L.imageOverlay(image.publicURL, localBounds).addTo(map);
            setBounds(localBounds);

            map.fitBounds(localBounds, true);
            //map.setZoom(map.getZoom()+1);
            map.setMaxBounds(localBounds);
            map.setMinZoom(map.getZoom());


        }
    }, [map, image, imageDimensions, setBounds, entity]);

    const mapProps = useMemo(() => {
        return Container === MapContainer ? {
            className: 'erwinindoormap',
            center: [0, 0],
            //zoom: component.zoom || 13,
            // zoom: 20,
            minZoom: component.minZoom || 12,
            maxZoom: component.maxZoom || 25,
            scrollWheelZoom: true,
            // style: { width: '100%', height: '80vh' },
            zoomSnap: 0,
            //maxBounds: bounds,
            ref: setMap,
            crs: CRS.Simple
        } : {};
    }, [Container]);

    useEffect(() => map && map.invalidateSize(), [map, fullscreenMode]);



    return <MapDiv className="indoor-map" >
        <FullscreenWrapper {...props} showFullscreenButton={true}>
            <Container {...mapProps} key='mapContainer'>
                {Container === MapContainer &&
                    <>
                        {bounds !== undefined ? <ImageOverlay url={image.publicURL} bounds={bounds} /> : React.Fragment}
                        {entity.strapiChildren.map((c, n) => {

                            const { Roles } = c;
                            const role = _.find(Roles, { strapi_component: 'role.indoor-map-child' });
                            const mapIcon = role.mapIcon ? { icon: role.mapIcon } : {};

                            return role !== undefined && role.position.coordinates !== undefined &&
                                <Marker position={role.position.coordinates} key={c.identifier} {...mapIcon}
                                // auskommentiert weil hover am touch gerät dazu führt das man zweimal tippen muss um Popup zu öffnen
                                // es müsste vielleicht eine Abfrage rein ob das Gerät hover kann weil am desktop ist es schon cool

                                    // eventHandlers={{
                                    //     mouseover: (e) => {
                                    //         e.target.openPopup();
                                    //     },
                                    //     mouseout: (e) => {
                                    //         setTimeout(() => {
                                    //             e.target.closePopup();
                                    //         }, 1000);
                                    //     }
                                    // }}
                                >
                                    <Popup  >
                                        <PopupDiv>
                                            <Link to={getEntityUrl({ entity: c, defaultLocale })}>
                                                {c.smallImage?.localFile !== undefined &&
                                                    <PopupImageDiv>
                                                        <GatsbyImage image={getImage(c.smallImage.localFile)} />
                                                    </PopupImageDiv>
                                                }
                                                <PopupTextDiv>
                                                    {role.label || c.title || c.identifier}
                                                </PopupTextDiv>
                                            </Link>
                                        </PopupDiv>
                                    </Popup>
                                </Marker>
                        })
                        }
                    </>
                }
            </Container >
        </FullscreenWrapper>
    </MapDiv>

}

export default IndoorMap;